import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Translation
import { useTranslation } from "react-i18next";

function RedemptionInfoStepper() {
  const { t } = useTranslation("common");

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Stepper
            sx={{
              "& .MuiStepConnector-line": {
                minHeight: "15px",
              },
            }}
            orientation="vertical"
          >
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step1")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step2")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step3")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step4")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel sx={{ mb: 1 }}>
                {t("redeemGIDR.modal.step5")}
              </StepLabel>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d274.0715685861385!2d106.84777239788271!3d-6.192996055103617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f443c67ee84d%3A0x47932521fbad33de!2sThe%20Gade%20Coffee%20Kramat%20Raya!5e0!3m2!1sen!2sid!4v1737990728786!5m2!1sen!2sid" 
                width={"100%"}
                height={350}
                style={{ border: 0, marginBottom: 10 }} 
                allowfullscreen="" 
                aria-hidden="false"
                tabIndex="0"
                title="Humanity First Indonesia"
                />
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step6")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step7")}</StepLabel>
            </Step>
            <Step active>
              <StepLabel>{t("redeemGIDR.modal.step8")}</StepLabel>
            </Step>
          </Stepper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function RedemptionInfoDialog({ ...props }) {
  const { t } = useTranslation("common");

  const { isOpen, isClose } = props;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={isClose}
      maxWidth={"sm"}
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Typography variant="h4" fontWeight="600">
          {t("redeemGIDR.modal.description")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <RedemptionInfoStepper />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={isClose}>{t("redeemGIDR.modal.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
